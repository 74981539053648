<template>
  <div style="margin-top: 20px;margin-left: 20px;">
    <el-empty v-if="!loginCheck" description="暂无数据"></el-empty>
    <div v-if="loginCheck">
      <el-table
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%">
        <el-table-column
            type="index"
            width="50"
            label="序号">
        </el-table-column>
        <el-table-column
            prop="topicName"
            label="题目">
          <template slot-scope="scope">
            <div class="wzyc" v-html="scope.row.topicName.replace(/\n/g, '<br/>')"></div>
          </template>
        </el-table-column>
        <el-table-column
            width="70"
            label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.topicType===1">判断题</span>
            <span v-if="scope.row.topicType===2">单选题</span>
            <span v-if="scope.row.topicType===3">填空题</span>
            <span v-if="scope.row.topicType===4">应用题</span>
            <span v-if="scope.row.topicType===5">多选题</span>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            width="200"-->
<!--            prop="optionsContent"-->
<!--            label="我的答案">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            width="200"-->
<!--            prop="answerContent"-->
<!--            label="参考答案">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            width="200"-->
<!--            prop="parse"-->
<!--            label="题目解析">-->
<!--        </el-table-column>-->
        <el-table-column
            width="80"
            prop="numberErrors"
            label="错误次数">
        </el-table-column>
        <el-table-column
            width="180"
            prop="createTime"
            label="创建时间">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="90">
          <template slot-scope="scope">
            <el-button @click="get(scope.row,scope)" type="text" size="small">查看</el-button>
            <el-button @click="remove(scope.row)" type="text" size="small">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center;margin-top: 10px;">
        <el-pagination
            layout="prev, pager, next"
            @current-change="jumpPage"
            :total="total">
        </el-pagination>
      </div>

      <div>
        <el-dialog title="错题查看"  :visible.sync="dialogTableVisible" >

          <div style="display: flex;justify-content: space-between;">
            <div>
              [
              <span v-if="dataForm.topicType===2">单选题</span>
              <span v-if="dataForm.topicType===5">多选题</span>
              ]
            </div>
            <div>
              <el-button type="text" v-if="dataFormIndex != 1" @click="st(dataFormIndex)">上一题</el-button>
              <el-button type="text" v-if="dataFormIndex != tableData.length" @click="xt(dataFormIndex)">下一题</el-button>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <div v-html="dataFormIndex +'.'+ dataForm.topicName"></div>
            <div v-for="it in dataForm.answerContent" :key="it">
              <el-alert
                  :title="it"
                  style="margin-top: 10px;"
                  type="info"
                  :closable="false"
              />
            </div>
            <div style="margin-top: 10px;">
              <el-button v-if="!jiexi" type="text" @click="jiexi=true">显示解析</el-button>
              <el-button v-if="jiexi" type="text" @click="jiexi=false">隐藏解析</el-button>
            </div>
            <div v-if="jiexi" style="font-size: 16px;">
              <div>错误次数：{{dataForm.numberErrors}}</div>
              <div v-html="dataForm.optionsContent"></div>
              <div v-html="dataForm.parse" style="color: #F56C6C;"></div>
            </div>

          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import token from "@/api/token"
import interview from "@/api/interview"
export default {
  name: "WrongQuestionBank",
  data() {
    return {
      loginCheck: false,
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      dialogTableVisible: false,
      formLabelWidth: '120px',
      dataForm: {},
      loading: false,
      jiexi: false,
      dataFormIndex: 1
    }
  },
  created() {
    if (token.getToken()) {
      this.loginCheck = true
      this.errorRecorPage()
    } else {
      this.$message({
        message: '请登录后，在进行操作',
        type: 'warning'
      })
    }
  },
  methods: {
    /**
     * 错误记录分页查询
     */
    errorRecorPage() {
      this.loading = true
      interview.errorRecorPage(this.queryForm).then(res => {
        this.tableData = res.data.records
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].answerContent = JSON.parse(this.tableData[i].answerContent)
          this.tableData[i].parse = this.tableData[i].parse.replace(/\n/g,'<br/>')
          let name = this.tableData[i].topicName
          name = name.replace(/<p>/g, '')
          name = name.replace(/<\/p>/g, '')
          this.tableData[i].topicName = name
        }
        this.total = res.data.total
        this.loading = false
      })
    },
    /**
     * 页面跳转
     * @constructor
     */
    jumpPage(pageNum){
      this.queryForm.pageNum = pageNum
      this.errorRecorPage()
    },
    get(row, data) {
      this.jiexi = false
      this.dataForm = row
      this.dataFormIndex = (data.$index + 1)
      this.dialogTableVisible = true
    },
    /**
     * 移除
     */
    remove(row) {
      this.$confirm('是否要删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        interview.errorRecorRemove({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            // this.queryForm.pageNum = 1
            this.errorRecorPage()
          }
        })
      });

    },
    /**
     * 上一题
     */
    st(index) {
      console.log(this.tableData[index - 2])
      this.dataForm = this.tableData[index - 2]
      this.dataFormIndex -= 1
      this.jiexi = false
    },
    /**
     * 下一题
     */
    xt(index) {
      console.log(this.tableData[index])
      this.dataForm = this.tableData[index]
      this.dataFormIndex += 1
      this.jiexi = false
    }
  }
}
</script>

<style scoped>
  .wzyc{
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
</style>
